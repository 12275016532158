import React, { useState, useEffect, useCallback } from 'react';

const OfferteGenerator = () => {
  const [wandOppervlakte, setWandOppervlakte] = useState('');
  const [plafondOppervlakte, setPlafondOppervlakte] = useState('');
  const [totaalPrijs, setTotaalPrijs] = useState(0);
  const [kortingPercentage, setKortingPercentage] = useState(0);
  const [prijsNaKorting, setPrijsNaKorting] = useState(0);
  const [afspraakDatum, setAfspraakDatum] = useState('');

  const WAND_PRIJS_PER_M2 = 25;
  const PLAFOND_PRIJS_PER_M2 = 35;

  const berekenOfferte = useCallback(() => {
    const wandPrijs = parseFloat(wandOppervlakte) * WAND_PRIJS_PER_M2;
    const plafondPrijs = parseFloat(plafondOppervlakte) * PLAFOND_PRIJS_PER_M2;
    const totaal = wandPrijs + plafondPrijs;
    setTotaalPrijs(totaal);

    let korting = 0;
    if (totaal >= 5000) {
      korting = 0.10;
    } else if (totaal >= 3000) {
      korting = 0.05;
    }
    setKortingPercentage(korting);

    const nieuwePrijsNaKorting = totaal * (1 - korting);
    setPrijsNaKorting(nieuwePrijsNaKorting);
  }, [wandOppervlakte, plafondOppervlakte]);

  useEffect(() => {
    berekenOfferte();
  }, [wandOppervlakte, plafondOppervlakte, berekenOfferte]);

  const containerStyle = {
    fontFamily: "'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px',
    backgroundColor: '#f5f7fa',
    borderRadius: '12px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  };

  const headerStyle = {
    textAlign: 'center',
    color: '#2c3e50',
    marginBottom: '30px',
  };

  const sectionStyle = {
    backgroundColor: 'white',
    padding: '25px',
    borderRadius: '8px',
    marginBottom: '25px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '10px',
    color: '#34495e',
    fontWeight: 'bold',
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    border: '1px solid #bdc3c7',
    borderRadius: '4px',
    fontSize: '16px',
    transition: 'border-color 0.3s',
  };

  const resultStyle = {
    backgroundColor: '#ecf0f1',
    padding: '25px',
    borderRadius: '8px',
    marginTop: '25px',
  };

  const discountStyle = {
    backgroundColor: '#e1f5fe',
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px',
    border: '1px solid #4fc3f7',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Tijmen Stukadoor - Professionele Offerte Generator</h1>
      
      <div style={sectionStyle}>
        <h2 style={{color: '#2c3e50', marginBottom: '15px'}}>Over Tijmen Stukadoor</h2>
        <p style={{lineHeight: '1.6', color: '#34495e'}}>
          Welkom bij Tijmen Stukadoor, uw expert in hoogwaardig stukwerk. Met jarenlange ervaring en een oog voor detail, 
          levert Tijmen vakmanschap van de hoogste kwaliteit. Onze toewijding aan uitstekend werk wordt onderstreept door 
          de vele positieve referenties van tevreden klanten.
        </p>
        <p style={{lineHeight: '1.6', color: '#34495e', marginTop: '10px'}}>
          Of het nu gaat om het stukadoren van wanden, plafonds of specialistisch sierwerk, Tijmen staat garant voor een 
          vlekkeloze afwerking die uw ruimte transformeert. Ervaar het verschil van een echt vakman en laat Tijmen uw 
          project naar een hoger niveau tillen.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={{color: '#2c3e50', marginBottom: '15px'}}>Bereken uw offerte</h2>
        <div style={{marginBottom: '20px'}}>
          <label htmlFor="wandOppervlakte" style={labelStyle}>
            Wand oppervlakte (m²):
          </label>
          <input
            id="wandOppervlakte"
            type="number"
            value={wandOppervlakte}
            onChange={(e) => setWandOppervlakte(e.target.value)}
            style={inputStyle}
          />
        </div>

        <div style={{marginBottom: '20px'}}>
          <label htmlFor="plafondOppervlakte" style={labelStyle}>
            Plafond oppervlakte (m²):
          </label>
          <input
            id="plafondOppervlakte"
            type="number"
            value={plafondOppervlakte}
            onChange={(e) => setPlafondOppervlakte(e.target.value)}
            style={inputStyle}
          />
        </div>
      </div>

      {totaalPrijs > 0 && (
        <div style={resultStyle}>
          <h3 style={{color: '#2c3e50', marginBottom: '15px'}}>Uw Offerte:</h3>
          <p>Wanden: €{(parseFloat(wandOppervlakte) * WAND_PRIJS_PER_M2).toFixed(2)}</p>
          <p>Plafond: €{(parseFloat(plafondOppervlakte) * PLAFOND_PRIJS_PER_M2).toFixed(2)}</p>
          <p style={{fontWeight: 'bold', fontSize: '18px'}}>Subtotaal: €{totaalPrijs.toFixed(2)}</p>
          
          {kortingPercentage > 0 && (
            <div style={discountStyle}>
              <p style={{color: '#0277bd', fontWeight: 'bold'}}>
                Korting: {(kortingPercentage * 100).toFixed(0)}% (€{(totaalPrijs * kortingPercentage).toFixed(2)})
              </p>
              <p style={{fontWeight: 'bold', fontSize: '20px', color: '#01579b'}}>
                Prijs na korting: €{prijsNaKorting.toFixed(2)}
              </p>
            </div>
          )}
        </div>
      )}

      <div style={sectionStyle}>
        <h2 style={{color: '#2c3e50', marginBottom: '15px'}}>Plan een afspraak</h2>
        <p style={{marginBottom: '15px', color: '#34495e'}}>
          Kies een datum voor een vrijblijvende afspraak. Tijmen komt graag bij u langs om de mogelijkheden te bespreken 
          en een nauwkeurige offerte op maat te maken.
        </p>
        <input
          type="date"
          value={afspraakDatum}
          onChange={(e) => setAfspraakDatum(e.target.value)}
          style={inputStyle}
        />
        {afspraakDatum && (
          <p style={{marginTop: '15px', color: '#27ae60', fontWeight: 'bold'}}>Gekozen afspraakdatum: {afspraakDatum}</p>
        )}
      </div>

      <div style={sectionStyle}>
        <h2 style={{color: '#2c3e50', marginBottom: '15px'}}>Contact</h2>
        <p style={{lineHeight: '1.6', color: '#34495e'}}>
          Heeft u vragen of wilt u meer informatie? Neem gerust contact op met Tijmen Stukadoor:
        </p>
        <p style={{lineHeight: '1.6', color: '#34495e', marginTop: '10px'}}>
          Telefoon: 06-12345678<br />
          E-mail: info@tijmenstukadoor.nl<br />
          Website: www.tijmenstukadoor.nl
        </p>
      </div>
    </div>
  );
};

export default OfferteGenerator;
